
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { replaceUrl } from '@/helpers/converters/url';

import { DatatableLink } from '@/types/ListTypes';

export interface LinkObject {
  label: string,
  link: DatatableLink
}

@Component({
  name: 'Links',
})
export default class Links extends Vue {
  @Prop()
  private readonly links?: Array<LinkObject>;

  private handleClick(link: LinkObject): void {
    const url = replaceUrl(link.link.url, link.link.data);

    let currentHash = this.$route.hash;
    if (this.$route.hash.length === 0) {
      currentHash = window.location.hash.replace('#', '');
    }

    let newHash = currentHash;
    if (newHash.length > 0) {
      newHash = `${currentHash}|${url}`;
    } else {
      newHash = url;
    }

    const routerData = {
      hash: newHash,
    };

    this.$router.push(routerData);
  }
}
